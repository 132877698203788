export default {
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media (max-width: 991px)': {
            flexDirection: 'column',
            textAlign: 'center',
            marginTop: '1rem'
        },
    },
    imageLogo: {
        width: '155px',
        pb: '5px'
    },
    thanksText: {
        fontSize: '45px',
        color: '#50D174',
        fontWeight: 700,
        lineHeight: '50px',
        paddingBottom: '1rem',
        '@media (max-width: 743px)': {
            marginLeft: '5px'
        },
        '@media (max-width: 465px)': {
            fontSize: '40px',
        },
        '@media (max-width: 390px)': {
            fontSize: '35px',
        }
    },
    thanksSubText: {
        color: '#6D7878',
        fontSize: '18px',
        '@media (max-width: 465px)': {
            fontSize: '16px',
        }
    },
    imageContent: {
        width: '65%',
        '@media (max-width: 991px)': {
            marginTop: '2rem'
        },
    },
    check: {
        width: '30px',
        marginLeft: '10px'
    }
}