import React from 'react'
import { Flex, Box, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentButtons from '@solid-ui-components/ContentButtons/ContentButtons'
import Icon from '@solid-ui-components/ContentIcon'

const ListItem = ({
  icon,
  iconProps,
  text,
  buttons,
  description,
  compact,
  vertical,
  center,
  middle //Centering for horizontal layout
}) => {

    const isTheContactPhone = text?.[0].text?.trim() === '+54 (11) 2040 1410';

    const Component = (
        <Flex
            sx={{
                flexDirection: vertical ? 'column' : 'row',
                alignItems: center ? `center` : `flex-start`,
                textAlign: center && vertical ? `center` : `unset`,
                justifyContent: middle ? `flex-start` : `flex-start`,
                '& + &': { mt: description ? (compact ? 3 : 4) : 2 },
                paddingLeft: '8px'
            }}
        >
            {
                icon?.src && (
                    <Box
                    sx={{
                        display: `inline-flex`,
                        flexShrink: 0,
                        [vertical ? 'mb' : 'mr']: compact ? 2 : 3
                        }}
                    >
                        <Icon
                        content={icon}
                        size='sm'
                        p={compact ? 1 : undefined}
                        {...iconProps}
                        />
                    </Box>
                )
            }
            <Box sx={{ alignSelf: vertical ? `auto` : `center` } }>
                <ContentText content={text} />
                <ContentButtons content={buttons} />
            </Box>
        </Flex>
    );
    
    return isTheContactPhone ? (
        <a target="_blank" href="https://api.whatsapp.com/send/?phone=5491120401410&text=%C2%A1Hola%21+Quiero+conocer+m%C3%A1s+de+Educabot&type=phone_number&app_absent=0" css={ css({ textDecoration: 'none' }) }>
            { Component }
        </a>
    ) : Component;
    
};

ListItem.defaultProps = {
  iconProps: {}
}

export default ListItem
