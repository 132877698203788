import React from 'react'
import { Container, Flex, Box, css, Text } from 'theme-ui'
import Divider from '@solid-ui-components/Divider'
import Reveal from '@solid-ui-components/Reveal'
import ListItem from '@solid-ui-components/ListItem'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentText from '@solid-ui-components/ContentText'
import Icon from '@solid-ui-components/ContentIcon'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  listItem: {
    flex: `1 1 0`,
    minWidth: 300,
    p: 2
  },
  itemDescription: {
    flexBasis: `3/5`,
    flexGrow: 1,
    order: [1, null, 0],
    mb: '20px'
  },
  contentContainer: { 
    height: `full`, 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  icon: {
    margin: 'auto'
  },
  card: {
    borderRadius: '8px',
    margin: 'auto',
    paddingTop: '5px',
    padding: '5px 10px 5px 10px',
    marginBottom: '15px'
  }
}

const FeaturesBlock05 = ({ content: { text, collection } }) => (
  <Container as={Reveal}>
    <Box sx={{ textAlign: `center` }}>
      {/* <ContentText content={text} /> */}
      <Text css={css({
        color: 'rgb(42, 32, 94)',
        fontSize: '30px',
        padding: '10px',
        fontWeight: 600
      })}>
        ¿Querés conocer nuestras soluciones?
      </Text>
    </Box>
    {collection && (
      <>
        <Flex sx={{ flexWrap: `wrap`, textAlign: 'center' }} m={-3}>
          {collection.map(
            ({ text, icon, collection, buttons, container }, index) => (
              <Reveal
                key={`item-${index}`}
                effect='fadeInGrow'
                delay={0.15 * (index + 1)}
                css={css(styles.listItem)}
              >
                <ContentContainer
                  content={container}
                  variant='cards.paper'
                  sx={styles.contentContainer}
                >
                  <Box css={css({ 
                    backgroundColor: text[2].color,
                    border: `1px solid ${text[3].color}`
                    })} 
                    sx={styles.card}
                  >
                    <ContentText content={text?.[0]} />
                  </Box> 
                  <Icon sx={styles.icon} content={icon} size='md' mb='3' />
                  <Flex sx={{ alignItems: `center`, flexWrap: `wrap` }}>
                    <ContentText
                      content={text?.slice(1)}
                      sx={styles.itemDescription}
                      mt={[3, null, 0]}
                    />
                    {collection && (
                      <Box sx={{ flexGrow: 1, mr: [3, null, 0] }}>
                        {collection.map((props, index) => (
                          <ListItem key={`item-${index}`} {...props} compact />
                        ))}
                      </Box>
                    )}
                  </Flex>
                  {buttons && (
                    <>
                      <ContentButtons content={buttons} />
                    </>
                  )}
                </ContentContainer>
              </Reveal>
            )
          )}
        </Flex>
      </>
    )}
  </Container>
)

export default WithDefaultContent(FeaturesBlock05)
