import React from 'react'
import { Flex, Box, css, Grid, Text, Container, Image, Link } from 'theme-ui'
import styles from './_styles'
import thanksLogo from '../../../../../../site/content/assets/flexiblocks/homepage/educabot/thanks.svg'
import logoBlue from '../../../../../../site/content/assets/flexiblocks/homepage/educabot/logoBlue.svg'
import check from '../../../../../../site/content/assets/flexiblocks/homepage/educabot/check.svg'

const ThanksMessage = () => {
  return (
    <Container>
      <Box className="first-content">
        <Box className="content">
          <Box className="section">
            <Link href={'/'}>
              <Image sx={styles.imageLogo} src={logoBlue} />
            </Link>
            <Box sx={styles.content}>
              <Box>
                <Text sx={styles.thanksText}>
                  ¡Muchas gracias por
                  <Text style={{ display: 'flex', alignItems: 'center' }}>contactarnos! <Image sx={styles.check} src={check} /></Text>
                </Text>
                <Text sx={styles.thanksSubText}>
                  En breve un miembro de nuestro equipo se <br />pondrá en contacto para asesorarte.
                </Text>
              </Box>
              <Box>
                <Image sx={styles.imageContent} src={thanksLogo} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default ThanksMessage
